<template>
  <div>
    <!-- Links (sit on top) -->
    <div class="w3-top">
      <div class="w3-row w3-large" style="background-color: white;">
        <div class="w3-col s3">
          <a href="#" class="w3-button w3-block">Accueil</a>
        </div>
        <div class="w3-col s3">
          <a href="#fonctionnalites" class="w3-button w3-block">Fonctionnalités</a>
        </div>
        <div class="w3-col s3">
          <a href="#tarif" class="w3-button w3-block">Tarif</a>
        </div>
        <div class="w3-col s3">
          <a href="#contact" class="w3-button w3-block">Contact</a>
        </div>
      </div>
    </div>
    <div class="w3-content" style="max-width:1100px;margin-top:80px;margin-bottom:80px">

      <!-- Content -->
      <div class="w3-content" style="max-width:1100px; margin-top:80px; margin-bottom:80px;">
        <div class="w3-panel"
          style="box-shadow: 0 4px 8px rgba(0,0,0,0.1); background-color: #f8f9fa; padding: 20px; border-radius: 8px;">
          <h1><b>Vill'AJour</b></h1>
          <div style="display: flex; justify-content: center;">
            <div
              style="width: 300px; height: 300px; display: flex; overflow: hidden; border: 2px solid orange; border-radius: 50%;">
              <img src="@/assets/logo.png" alt="Dynamic Local Management"
                style="width: 100%; height: 100%; object-fit: cover; border-radius: 50%;">
            </div>
          </div>
          <p style="font-size: 1.2em; color: #333;">La gestion communale réinventée pour la dynamique locale.</p>
        </div>

        <!-- Detailed description section from project document -->
        <div class="w3-row" style="padding-top: 40px; margin-bottom: 40px; border-bottom: 2px solid #E08547;">
          <div class="w3-col m6" style="padding-right: 20px; padding-bottom: 20px; border-right: 2px solid #E08547;">
            <h2 style="color: #E08547;">Contexte</h2>
            <p style="text-align: justify; line-height: 1.6; color: #555;">
              À la constitution de notre équipe tous issus de villages ou de petites villes, nous avons constaté
              certaines
              problématiques au sein de ceux-ci, telles que la difficulté de rendre l'accès aux informations efficaces
              et
              la commune dynamique.
            </p>
          </div>
          <div class="w3-col m6" style="padding-left: 20px; padding-bottom: 20px;">
            <h2 style="color: #E08547;">Objectifs</h2>
            <p style="text-align: justify; line-height: 1.6; color: #555;">
              Permettre aux communes et aux communautés de commune de manager leurs espaces communaux et l'événementiel
              aux seins de leurs communes, mettant en avant les atouts culturel et social ainsi que la communication.
            </p>
          </div>
        </div>

        <div class="w3-panel"
          style="padding-top: 40px; margin-top: 40px; box-shadow: 0 4px 8px rgba(0,0,0,0.1); background-color: #f8f9fa; padding: 20px; border-radius: 8px;">
          <h2 style="color: #E08547;">Fonctionnalités</h2>
          <p style="text-align: justify; line-height: 1.6; color: #555;">
            Le projet inclut diverses fonctionnalités telles que l'authentification sécurisée, la gestion des services
            communaux et administratifs, la prise de rendez-vous en ligne, et plus encore pour faciliter la gestion et
            la
            communication au sein des communautés.
          </p>
        </div>
      </div>



      <!-- Grid for Features -->
      <div class="w3-row-padding" id="fonctionnalites">
        <div class="w3-center w3-padding-64">
          <span class="w3-xlarge w3-bottombar w3-border-dark-grey w3-padding-16">Ce que nous offrons</span>
        </div>

        <div class="w3-row w3-equal-height" style="box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);">

          <div class="w3-col l3 m6 w3-container w3-padding-16"
            style="border-right: 2px solid white; display: flex; flex-direction: column; justify-content: space-between;">
            <div>
              <h3>Gestion des Accès</h3>
              <p>Accès sécurisé et personnalisé pour les employés municipaux, facilitant la gestion administrative.</p>
            </div>
            <div></div>
          </div>

          <div class="w3-col l3 m6 w3-container w3-padding-16"
            style="border-right: 2px solid white; display: flex; flex-direction: column; justify-content: space-between;">
            <div>
              <h3>Rendez-vous en Ligne</h3>
              <p>Système de réservation simplifié pour les services municipaux, améliorant l'interaction citoyenne.</p>
            </div>
            <div></div>
          </div>

          <div class="w3-col l3 m6 w3-container w3-padding-16"
            style="border-right: 2px solid white;display: flex; flex-direction: column; justify-content: space-between;">
            <div>
              <h3>Communication Communale</h3>
              <p>Diffusion et gestion centralisée des annonces et événements, renforçant le lien communautaire.</p>
            </div>
            <div></div>
          </div>

          <div class="w3-col l3 m6 w3-container w3-padding-16"
            style=" display: flex; flex-direction: column; justify-content: space-between;">
            <div>
              <h3>Portail Municipal</h3>
              <p>Une vitrine complète sur les services, la tarification et les informations municipales essentielles.
              </p>
            </div>
            <div></div>
          </div>

        </div>
      </div>



      <!-- Pricing Plans -->
      <div class="w3-container" id="tarif" style="max-width: 800px; margin: auto;">
        <div class="w3-center w3-padding-64">
          <h3>Plans Tarifaires</h3>
          <p>Choisissez un plan tarifaire qui vous convient, avec une tarification flexible basée sur la population et
            des modules optionnels pour améliorer votre service.</p>
        </div>

        <div class="w3-row-padding" id="tarif" style="justify-content: center; display: flex; flex-wrap: wrap;">
          <!-- Pricing for Basic Subscription -->
          <div class="w3-card w3-margin-bottom w3-col l5 m5"
            style="box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1); margin-right: 2%;">
            <ul class="w3-ul w3-border w3-center w3-hover-shadow">
              <li style="background-color: #E08547; color: white;" class="w3-xlarge w3-padding-32">Abonnement de Base
              </li>
              <li class="w3-padding-16"><b>Coût par habitant par mois:</b> 0.10€</li>
              <li class="w3-padding-16"><b>Coût par habitant par an:</b> 1.20€</li>
              <li class="w3-padding-24">
                <button onclick="location.href='#contact';" class="w3-button w3-black">Contactez-nous</button>
              </li>
            </ul>
          </div>

          <!-- Pricing for Subscription with Additional Modules -->
          <div class="w3-card w3-margin-bottom w3-col l5 m5" style="box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);">
            <ul class="w3-ul w3-border w3-center w3-hover-shadow">
              <li style="background-color: #E08547; color: white;" class="w3-xlarge w3-padding-32">Abonnement avec
                Modules</li>
              <li class="w3-padding-16"><b>Inclus tous les avantages de l'abonnement de base plus:</b></li>
              <li class="w3-padding-16">Salle des Fêtes: <b>100€ par mois</b></li>
              <li class="w3-padding-16">Écoles: <b>150€ par mois</b></li>
              <li class="w3-padding-16">Autres modules personnalisés: <b>Prix sur demande</b></li>
              <li class="w3-light-grey w3-padding-24">
                <button onclick="location.href='#contact';" class="w3-button w3-black">Contactez-nous</button>
              </li>
            </ul>
          </div>
        </div>

      </div>

      <div class="w3-container w3-padding-64" id="contact" style="max-width: 1100px; margin: auto;">
  <div class="w3-center">
    <h1 class="form-title">Nous contacter</h1>
    <p class="form-description">Veuillez remplir ce formulaire pour nous fournir des informations sur votre mairie.</p>
  </div>

  <div class="w3-row-padding" style="display: flex; justify-content: center; align-items: flex-start;">
   

    <!-- Form Fields -->
    <div class="w3-col m6 contact-form-container" style="width: 100%; max-width: 600px;">
      <form class="contact-form" action="/action_page.php" target="_blank" style="display: flex; flex-wrap: wrap;">
        <div class="w3-section w3-col s12 m6" style="padding-right: 10px;">
          <label>Email</label>
          <input class="w3-input input-field" type="email" name="email" required>
        </div>
        <div class="w3-section w3-col s12 m6" style="padding-left: 10px;">
          <label>Nom de la Mairie</label>
          <input class="w3-input input-field" type="text" name="name" required>
        </div>
        <div class="w3-section w3-col s12 m6" style="padding-right: 10px;">
          <label>Adresse</label>
          <input class="w3-input input-field" type="text" name="address" required>
        </div>
        <div class="w3-section w3-col s12 m6" style="padding-left: 10px;">
          <label>Numéro de CIRET</label>
          <input class="w3-input input-field" type="text" name="ciret" required>
        </div>
        <div class="w3-section w3-col s12 m6" style="padding-right: 10px;">
          <label>Code INSEE</label>
          <input class="w3-input input-field" type="text" name="insee" required>
        </div>
        <div class="w3-section w3-col s12 m6" style="padding-left: 10px;">
          <label>Code Postal</label>
          <input class="w3-input input-field" type="text" name="postalCode" required>
        </div>
        <div class="w3-section w3-col s12 m6" style="padding-right: 10px;">
          <label>Nombre d'habitants</label>
          <select class="w3-select input-field" name="population" required>
            <option value="" disabled selected>Choisissez une option</option>
            <option value="- de 500">- de 500</option>
            <option value="entre 500 et 1000">entre 500 et 1000</option>
            <option value="entre 1000 et 3000">entre 1000 et 3000</option>
            <option value="entre 3000 et 5000">entre 3000 et 5000</option>
            <option value="entre 5000 et 10000">entre 5000 et 10000</option>
            <option value="plus de 10000">plus de 10000</option>
          </select>
        </div>
        <div class="w3-section w3-col s12 m6" style="padding-left: 10px;">
          <label>Message</label>
          <textarea class="w3-input input-field" name="message" required></textarea>
        </div>
        <div class="w3-section w3-col s12">
          <input class="w3-check" type="checkbox" name="terms" required>
          <label>J'accepte les Conditions d'utilisation</label>
        </div>
        <div class="w3-center w3-col s12" style="margin-top: 20px;">
          <button type="submit" class="w3-button submit-btn">Soumettre</button>
        </div>
      </form>
      <div class="image-credit w3-center">
      </div>
    </div>
  </div>
</div>
</div>

    <!-- Footer -->

    <footer class="w3-container w3-padding-32 w3-light-grey w3-center">
      <h4>Nos réseaux</h4>
      <a href="#" class="w3-button w3-black w3-margin"><i class="fa fa-arrow-up w3-margin-right"></i>To the
        top</a>
      <div class="w3-xlarge w3-section">
        <i class="fa fa-instagram w3-hover-opacity"></i>
        <i class="fa fa-twitter w3-hover-opacity"></i>
        <i class="fa fa-linkedin w3-hover-opacity"></i>
      </div>
      <p>Powered by <a href="" title="Vill'AJour" target="_blank" class="w3-hover-text-green">Vill'AJour</a></p>
    </footer>
  </div>
</template>

<style scoped>
.form-title {
  font-size: 2em;
  margin-bottom: 10px;
  color: #E08547;
}

.form-description {
  margin-bottom: 20px;
  color: #666;
}

.form-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-image-container {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #E08547;
}

.form-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact-form-container {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.input-field {
  border: none;
  border-bottom: 2px solid #ddd;
  width: 100%;
  padding: 10px 0;
  margin-bottom: 10px;
  transition: border-bottom 0.3s ease;
}

.input-field:focus {
  border-bottom: 2px solid #E08547 !important;
  box-shadow: none;
}

.submit-btn {
  background-color: #E08547;
  color: #fff;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #c96c39;
}

.image-credit {
  margin-top: 20px;
  text-align: center;
}

.image-credit a {
  color: #E08547;
  text-decoration: none;
}
</style>